/*
 * @Author: your name
 * @Date: 2021-05-07 17:02:29
 * @LastEditTime: 2021-06-22 14:06:58
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /Lenovo/frontend/sites/application/index.tsx
 */
import "promise-polyfill/src/polyfill";
import "core-js/es";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";

import "./styles/app.scss";
import "./icons/index";
import { ScrollToTop, Banner, CollectModal, Icon } from "./components/shared";
import LenovoRoute from "./routes";
import getBaseName from "./utils/getBaseName";
import Global from "./Global";
import { observer } from "mobx-react";
import { Carousel, Divider } from "antd";
import globalStore from "./stores/globalStore";

const App = () => {
  const basename = getBaseName();
  const { globalVar, cooperateExpress } = useContext(globalStore);

  const Entry = (
    <div className="lenovo-entry__wrapper">
      <a className="lenovo-entry" href={"/cooperation"} target="_self">
        <div className="panel__banner-left">
          <Icon name="cooperation-report" size={40} />
        </div>
        <Divider type="vertical" />
        <Carousel
          className="lenovo-entry__carousel"
          autoplay
          infinite
          dotPosition="left"
          dots={false}
        >
          {cooperateExpress.length > 0 &&
            cooperateExpress.map((item: any, index: number) => (
              <div className="panel__banner-main" key={index}>
                <p className="panel__banner-main-title">{item.title}</p>
                <p className="panel__banner-main-desc">{item.summary}</p>
              </div>
            ))}
        </Carousel>
      </a>
    </div>
  );

  return (
    <>
      {globalVar.inLibrary && <Banner />}
      {globalVar.inList && Entry}
      <CollectModal />
      <div
        className={`lenovo__layout ${globalVar.inLibrary &&
          "lenovo__layout--library"} ${globalVar.inList &&
          "lenovo__layout--library-list"}`}
      >
        <Router basename={basename}>
          <LastLocationProvider>
            <ScrollToTop />
            <Global />
            <Switch>
              <Route
                path="/"
                exact
                render={() => <Redirect to={"/library/institutions"} />}
              />
              <Route
                path="/institutions"
                exact
                render={() => <Redirect to={"/library/institutions"} />}
              />
              <Route
                path="/solutions"
                exact
                render={() => <Redirect to={"/library/solutions"} />}
              />
              <Route
                path="/experts"
                exact
                render={() => <Redirect to={"/library/experts"} />}
              />
              <Redirect
                from="/institutions/:id"
                to="/library/institutions/:id"
              />
              <Redirect from="/solutions/:id" to="/library/solutions/:id" />
              <Redirect from="/experts/:id" to="/library/experts/:id" />
              <Redirect
                from="/business-cases/:id"
                to="/library/business-cases/:id"
              />
              <LenovoRoute />
            </Switch>
          </LastLocationProvider>
        </Router>
      </div>
    </>
  );
};

export default observer(App);
