import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { observer } from "mobx-react";
// import { useHistory } from "react-router-dom";

import "./style.scss";
import { Nav, Footer, CodeEntry, Breadcrumbs, Icon } from "../shared/index";
import { Pagination } from "antd";

import ExpressListStore from "../../stores/expressListStore";
import { isMobile } from "../../utils/tools";
import _ from "underscore";
import ExpressCard from "./ExpressCard";

interface Props {
  [key: string]: any;
}

const ExpressList: React.FC<Props> = () => {
  // const { id } = useParams();
  const {
    data,
    fetchData,
    pagination,
    setPageNum,
    initPage,
    loading,
  } = useContext(ExpressListStore);

  useEffect(() => {
    initPage();
    fetchData();
  }, []);

  const onScrollEvent = _.debounce(() => {
    const dom = document.querySelector(".content");
    if (
      isMobile() &&
      dom &&
      pagination.hasNextPage &&
      !loading &&
      dom.scrollTop + dom.clientHeight > dom.scrollHeight * 0.8
    ) {
      setPageNum(pagination.currentPage + 1);
      fetchData(true);
    }
  }, 500);

  console.log(data.expresses);

  return (
    <div className="lenovo-express-list">
      <Helmet>
        <title>快报列表 | 联想数字生态平台</title>
      </Helmet>
      <Nav onScrollCapture={onScrollEvent}>
        <div className="lenovo-express-list__content">
          <CodeEntry />
          <Breadcrumbs
            items={data.breadcrumbs}
            separator={<Icon name="breadcrumbs-triangle" size={5} />}
          />
          <div className="content__list">
            <p className="content__list-title">合作快报</p>
            <div className="content__list-wrapper">
              {data.expresses &&
                data.expresses.map((item: any, id: string) => (
                  <ExpressCard key={id} data={item} />
                ))}
            </div>
            {data.expresses && !!data.expresses.length && (
              <Pagination
                className="lenovo-express__pagination"
                hideOnSinglePage
                showSizeChanger={false}
                total={pagination.totalCount}
                current={pagination.currentPage}
                defaultCurrent={1}
                defaultPageSize={pagination.pageSize}
                onChange={(page: number) => {
                  setPageNum(page);
                  fetchData();
                }}
              />
            )}
          </div>
        </div>
        <Footer />
      </Nav>
    </div>
  );
};

export default observer(ExpressList);
